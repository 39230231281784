<mtx-popover #additionalFiltersPopover="mtxPopover" [closeOnBackdropClick]="true" [hasBackdrop]="true"
    [position]="['below', 'after']">
    <app-assets-table-filter #assetsTableFilterComponent [context]="this"
        (onFilterModel)="setFilterModel($event)"></app-assets-table-filter>
</mtx-popover>

<div class="assets-table-root">
    <div class="table-actions">
        <div class="section">
            <div>ASSETS</div>
            <span *ngIf="subscriptionService.online" class="material-symbols-outlined color-green prevent-select"
                matTooltipPosition="above" matTooltip="Sync ON: Changes will be saved AND pushed to other users">
                wifi
            </span>
            <span *ngIf="!subscriptionService.online" class="material-symbols-outlined color-warn-400 prevent-select"
                matTooltipPosition="above" matTooltip="Sync OFF: Changes will be saved BUT NOT pushed to other users">
                wifi_off
            </span>
        </div>

        <div class="flex-row gap-normal align-items-center">


            <button mat-icon-button matTooltip="Reload data" matTooltipPosition="above" (click)="reloadTable()">
                <mat-icon fontSet="material-symbols-outlined">refresh</mat-icon>
            </button>

            <div class="actions-divider"></div>

            <div class="mat-body-2">General search</div>

            <input type="text" class="general-search-input" placeholder="Search"
                (keyup.enter)="onGeneralSearchChange($event)" [(ngModel)]="generalSearchValue">

            <div class="actions-divider"></div>

            <button mat-icon-button (click)="
          clearAllFilters(); assetsTableFilterComponent.disableAllFilters()
        " [disabled]="!canClearFilter" matTooltip="Remove all filters" matTooltipPosition="above">
                <mat-icon>filter_alt_off</mat-icon>
            </button>

            <button mat-icon-button [mtxPopoverTriggerFor]="additionalFiltersPopover" mtxPopoverTriggerOn="click"
                [mtxPopoverTriggerData]="{ context: this }" matTooltip="Edit additional filters"
                matTooltipPosition="above" [color]="additionalFilters.on ? 'primary' : ''">
                <mat-icon>filter_alt_on</mat-icon>
            </button>

            <div class="actions-divider"></div>

            <div class="mat-body-2">Selected: {{ selectedRows.length }}</div>

            <button *ngIf="selectedRows.length > 0" mat-button (click)="filterSelectedRows()">
                <div class="flex-row gap-tiny align-items-center">
                    <mat-icon>filter_list</mat-icon>
                    <span style="line-height: initial">filter selected</span>
                </div>
            </button>

            <button *ngIf="selectedRows.length > 0" mat-button (click)="deselectSelectedRows()">
                <div class="flex-row gap-tiny align-items-center">
                    <mat-icon>clear</mat-icon>
                    <span style="line-height: initial">clear selection</span>
                </div>
            </button>
        </div>

        <div class="table-actions-right-buttons">
            <button mat-flat-button color="accent" style="min-width: unset; padding: 0 8px"
                [matMenuTriggerFor]="moreActionsMenu">
                <div class="flex-row align-items-center">
                    <mat-icon fontSet="material-symbols-outlined">more_horiz</mat-icon>
                </div>
            </button>
            <mat-menu #moreActionsMenu="matMenu">
                <ng-template matMenuContent>
                    <button mat-menu-item (click)="exportAssetsToExcel(false)">
                        <mat-icon fontSet="material-symbols-outlined">download</mat-icon>
                        <span>Export Filtered</span>
                    </button>

                    <button mat-menu-item (click)="exportAssetsToExcel(true)" [disabled]="!hasSelectedRows">
                        <mat-icon fontSet="material-symbols-outlined">download</mat-icon>
                        <span>Export Selected</span>
                    </button>

                    <mat-divider></mat-divider>

                    <button appNotReader mat-menu-item [matMenuTriggerFor]="inventoryMenu"
                        (menuOpened)="onOpenMenuInventory()">
                        <mat-icon fontSet="material-symbols-outlined">inventory</mat-icon>
                        <span> Start Inventory ...</span>
                    </button>
                    <mat-menu #inventoryMenu="matMenu">
                        <ng-template matMenuContent>
                            <button mat-menu-item (click)="startInventory('all')">
                                <span>
                                    ... with <span class="font-weight-500">all</span> assets</span>
                            </button>
                            <button mat-menu-item [disabled]="!hasSelectedRows" (click)="startInventory('selected')">
                                <span>
                                    ... with
                                    <span class="font-weight-500">
                                        {{ selectedRows.length }} selected
                                    </span>
                                    {{ selectedRows.length === 1 ? "asset" : "assets" }}</span>
                            </button>
                            <button mat-menu-item [disabled]="
                  (snapshotDistinctColumnValues.get('id')?.length ?? 0) === 0
                " (click)="
                  startInventory(
                    'filtered',
                    snapshotDistinctColumnValues.get('id')
                  )
                ">
                                <span>
                                    ... with
                                    <span class="font-weight-500">
                                        {{ snapshotDistinctColumnValues.get("id")?.length ?? 0 }}
                                        filtered
                                    </span>
                                    assets</span>
                            </button>
                        </ng-template>
                    </mat-menu>

                    <button appNotReader mat-menu-item [matMenuTriggerFor]="returnToCustomerMenu"
                        (menuOpened)="onOpenMenuReturnToCustomer()">
                        <mat-icon fontSet="material-symbols-outlined">local_shipping</mat-icon>
                        <span>Start 'Return to Customer'</span>
                    </button>

                    <mat-menu #returnToCustomerMenu="matMenu">
                        <ng-template matMenuContent>
                            <button mat-menu-item [disabled]="!hasSelectedRows"
                                (click)="startReturnToCustomer('selected')">
                                <span>
                                    ... with
                                    <span class="font-weight-500">
                                        {{ selectedRows.length }} selected
                                    </span>
                                    {{ selectedRows.length === 1 ? "asset" : "assets" }}</span>
                            </button>
                            <button mat-menu-item [disabled]="
                  (snapshotDistinctColumnValues.get('id')?.length ?? 0) === 0
                " (click)="
                  startReturnToCustomer(
                    'filtered',
                    snapshotDistinctColumnValues.get('id')
                  )
                ">
                                <span>
                                    ... with
                                    <span class="font-weight-500">
                                        {{ snapshotDistinctColumnValues.get("id")?.length ?? 0 }}
                                        filtered
                                    </span>
                                    assets</span>
                            </button>
                        </ng-template>
                    </mat-menu>

                    <button appNotReader mat-menu-item [disabled]="!hasSelectedRows" (click)="onDeleteSelectedAssets()">
                        <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                        <span> Delete selected assets ...</span>
                    </button>
                </ng-template>
            </mat-menu>

            <lib-select-button appNotReader icon="shield_question" text="Set Missing" color="accent"
                [disabled]="!hasSelectedRows" (onClick)="setAssetsMissingState(true)">
                <button mat-menu-item (click)="setAssetsMissingState(false)">
                    <mat-icon fontSet="material-symbols-outlined">verified_user</mat-icon>
                    <span>Set Available</span>
                </button>
            </lib-select-button>

            <button appNotReader mat-flat-button (click)="onClickNewPlan()" [disabled]="!hasSelectedRows"
                color="accent">
                <mat-icon fontSet="material-symbols-outlined">add</mat-icon>
                <span>Plan</span>
            </button>

            <button appNotReader mat-flat-button (click)="onClickBookAction()" [disabled]="!hasSelectedRows || hasPlan"
                color="accent">
                <mat-icon fontSet="material-symbols-outlined">add</mat-icon>
                <span>Realtime Action</span>
            </button>
        </div>
    </div>
    <div class="table-wrapper">
        <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-alpine" [gridOptions]="gridOptions"
            [rowClassRules]="rowClassRules" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
            [rowSelection]="'multiple'" [animateRows]="true" (gridReady)="onGridReady($event)" rowModelType="infinite"
            [pagination]="false" [paginationAutoPageSize]="true" [cacheBlockSize]="25" [rowHeight]="26"
            [rowMultiSelectWithClick]="true" (selectionChanged)="onSelectionChanged($event)" [getRowId]="getRowId"
            (filterChanged)="onFilterChanged($event)" (contextmenu)="onTableRightClick($event)"
            (mousedown)="onTableMouseDown($event)" (cellContextMenu)="onCellContextMenu($event)"
            (columnMoved)="onColumnMoved($event)" [columnHoverHighlight]="false" [tooltipShowDelay]="1000"
            [enableCellTextSelection]="false" [ensureDomOrder]="true" (dragstart)="onDragStart($event)">
        </ag-grid-angular>
        <app-ag-sidebar #agSidebar [gridApi]="gridApi" (restoreDefaultColumnOrder)="onRestoreDefaultColumnOrder()"
            (filterInventory)="onFilterInventory($event)">
        </app-ag-sidebar>
    </div>
</div>

<div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y" #divContextMenuTrigger [matMenuTriggerFor]="contextMenu"></div>
<mat-menu #contextMenu="matMenu" (closed)="onContextMenuClosed()" class="no-padding">
    <ng-template matMenuContent let-context="context">

        <button mat-menu-item (click)="copyToClipboard(context.data.id)">
            <mat-icon fontSet="material-symbols-outlined">content_copy</mat-icon>
            <span>Copy ID to clipboard</span>
        </button>
        <button mat-menu-item (click)="showQRCode([context.data])">
            <mat-icon fontSet="material-symbols-outlined">qr_code</mat-icon>
            <span>Show ID as QR Code</span>
        </button>
        <button *ngIf="selectedRows.length > 1" mat-menu-item (click)="showQRCode(selectedRows)" [disabled]="
            !context.isMultiEditVisible">
            <mat-icon fontSet="material-symbols-outlined">qr_code</mat-icon>
            <span>[All Selected] Show IDs as QR Codes</span>
        </button>


        <mat-divider></mat-divider>

        <button mat-menu-item (click)="copyToClipboard(context.columnValue)">
            <mat-icon fontSet="material-symbols-outlined">content_copy</mat-icon>
            <span>Copy value to clipboard</span>
        </button>

        <mat-divider></mat-divider>

        <button appNotReader mat-menu-item [disabled]="!context.data.currentPlanName" [matMenuTriggerFor]="goTo"
            [matMenuTriggerData]="{ context: context }">
            <mat-icon fontSet="material-symbols-outlined">build</mat-icon>
            <span>Current Plan ...</span>
        </button>

        <button appNotReader mat-menu-item [disabled]="context.futurePlans.length === 0"
            [matMenuTriggerFor]="goToFuturePlansMenu" [matMenuTriggerData]="{ context: context }">
            <mat-icon fontSet="material-symbols-outlined">build</mat-icon>
            <span>Future Plans ...</span>
        </button>

        <mat-divider></mat-divider>

        <button mat-menu-item [matMenuTriggerFor]="assetDefectsMenu" [matMenuTriggerData]="{ context: context }"
            (menuOpened)="onOpenedContextMenuAssetDefects(context.data.id)">
            <mat-icon fontSet="material-symbols-outlined">heart_broken</mat-icon>
            <span>Asset Defects ...</span>
        </button>

        <mat-divider></mat-divider>

        <button mat-menu-item (click)="
        editAssets(
          context.columnPropertyId,
          context.columnPropertyHeaderName,
          context.selectedNode
        )
      " [disabled]="context.columnPropertyIsNotEditable">
            <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
            <span>Edit {{ context.columnPropertyHeaderName }}</span>
        </button>

        <button appNotReader *ngIf="selectedRows.length > 1" mat-menu-item (click)="
        editAssets(
          context.columnPropertyId,
          context.columnPropertyHeaderName
        )
      " [disabled]="
        !context.isMultiEditVisible || context.columnPropertyIsNotEditable
      ">
            <mat-icon fontSet="material-symbols-outlined">edit_note</mat-icon>
            <span>[All Selected] Edit {{ context.columnPropertyHeaderName }}</span>
        </button>

        <mat-divider></mat-divider>

        <button mat-menu-item [matMenuTriggerFor]="assetHistory" [matMenuTriggerData]="{ context: context }">
            <mat-icon fontSet="material-symbols-outlined">history</mat-icon>
            <span>Asset History ...</span>
        </button>
    </ng-template>
</mat-menu>

<mat-menu #goTo="matMenu">
    <ng-template matMenuContent let-context="context">
        <button mat-menu-item (click)="removeAssetFromPlan(context.data, context.data.currentPlanName)"
            [disabled]="!context.data.currentPlanName">
            <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
            <span>... remove asset from plan</span>
        </button>
        <button mat-menu-item (click)="goToCurrentPlanByName(context.data.currentPlanName, 'above')"
            [disabled]="!context.data.currentPlanName">
            <mat-icon fontSet="material-symbols-outlined">arrow_upward</mat-icon>
            <span>... show above</span>
        </button>
        <button mat-menu-item (click)="goToCurrentPlanByName(context.data.currentPlanName, 'zen')"
            [disabled]="!context.data.currentPlanName">
            <mat-icon fontSet="material-symbols-outlined">self_improvement</mat-icon>
            <span>... show in Zen Mode</span>
        </button>
    </ng-template>
</mat-menu>

<mat-menu #goToFuturePlansMenu="matMenu">
    <ng-template matMenuContent let-context="context">
        <button *ngFor="let plan of context.futurePlans" mat-menu-item [disabled]="context.futurePlans.length === 0"
            [matMenuTriggerFor]="goToFuturePlanMenu" [matMenuTriggerData]="{ context: context, plan: plan }">
            <mat-icon fontSet="material-symbols-outlined">local_parking</mat-icon>
            <span>{{ plan.name }} ...</span>
        </button>
    </ng-template>
</mat-menu>

<mat-menu #goToFuturePlanMenu="matMenu">
    <ng-template matMenuContent let-context="context" let-plan="plan">
        <button mat-menu-item (click)="removeAssetFromPlan(context.data, context.data.currentPlanName)"
            [disabled]="!context.data.currentPlanName">
            <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
            <span>... remove asset from plan</span>
        </button>
        <button mat-menu-item (click)="goToFuturePlan(plan, 'above')">
            <mat-icon fontSet="material-symbols-outlined">arrow_upward</mat-icon>
            <span>... show above</span>
        </button>
        <button mat-menu-item (click)="goToFuturePlan(plan, 'zen')">
            <mat-icon fontSet="material-symbols-outlined">self_improvement</mat-icon>
            <span>... show in Zen Mode</span>
        </button>
    </ng-template>
</mat-menu>

<mat-menu #assetHistory="matMenu">
    <ng-template matMenuContent let-context="context">
        <button mat-menu-item (click)="showHistory(context.data.id)">
            <mat-icon fontSet="material-symbols-outlined">grid_view</mat-icon>
            <span>... view</span>
        </button>
        <button mat-menu-item (click)="exportHistoryToExcel(context.data.id)">
            <mat-icon fontSet="material-symbols-outlined">save</mat-icon>
            <span>... export</span>
        </button>
    </ng-template>
</mat-menu>

<mat-menu #assetDefectsMenu="matMenu">
    <ng-template matMenuContent let-context="context">
        <button mat-menu-item (click)="viewDefects(context.data.id)"
            [disabled]="!context.assetHasDefectRecords.get(context.data.id)">
            <mat-icon fontSet="material-symbols-outlined">history</mat-icon>
            <span>... view history</span>
        </button>
        <button *ngIf="
        context.data.defectType === 1 && context.data.defectState !== 1;
        else reportDefectElement
      " mat-menu-item (click)="reportDefect(context.data.id, true)">
            <mat-icon fontSet="material-symbols-outlined">task_alt</mat-icon>
            <span>... verify latest</span>
        </button>
        <ng-template #reportDefectElement>
            <button mat-menu-item (click)="reportDefect(context.data.id, false)">
                <mat-icon fontSet="material-symbols-outlined">playlist_add</mat-icon>
                <span>... create record</span>
            </button>
        </ng-template>
    </ng-template>
</mat-menu>