import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { environment } from 'projects/desktop/src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subscription } from 'rxjs';
import { SelectionService } from '../../services/selection.service';

import 'projects/shared/src/lib/extensions/array';
import { TenantOutput } from 'projects/shared/src/lib/graphql/output/tenantOutput';
import { Router, NavigationEnd } from '@angular/router';
import { InitialLoadingService } from '../../services/initial-loading.service';
import { UserConfigService } from 'projects/shared/src/lib/services/user-config.service';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/angular-ivy';
import { Apollo, gql } from 'apollo-angular';
import { UserRoleSubscriptionRoot } from 'projects/shared/src/lib/graphql/crud/userRole';
import { RoleId } from 'projects/shared/src/lib/graphql/output/roleOutput';
import { MatDialog } from '@angular/material/dialog';
import { RoleUpdatesDialogComponent } from '../../component-dialogs/role-updates-dialog/role-updates-dialog.component';
import { roles } from 'projects/shared/src/lib/graphql/enums/role';
import { MatDrawer } from '@angular/material/sidenav';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { NotificationService } from '../../services/notificationService/notification.service';
import { AssetService } from 'projects/shared/src/lib/services/asset.service';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
    // #region Public Properties

    account: AccountInfo | undefined;
    year = new Date().toISOString().slice(0, 4);
    version = environment.version;

    activeTabRouterLink: string = 'home';

    // #endregion Public Properties

    // #region Private Properties

    private _routerEvents: Subscription | undefined;
    private _viewInitialized = false;
    #userRoleSubscription: Subscription | undefined;
    #isRoleUpdatesDialogOpen = false;

    // #endregion Private Properties

    // #region Init

    constructor(
        private _msalService: MsalService,
        private clipboard: Clipboard,
        public initialLoadingService: InitialLoadingService,
        public selectionService: SelectionService,
        private _userConfigService: UserConfigService,
        private router: Router,
        private _apollo: Apollo,
        private _matDialog: MatDialog,
        private _toastService: DesktopToastService,
        private _notificationService: NotificationService,
        private _assetService: AssetService
    ) {
        this._routerEvents = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (!this._viewInitialized) {
                    this._viewInitialized = true;

                    if (event.url === '/') {
                        this.activeTabRouterLink = 'home';
                    } else {
                        this.activeTabRouterLink = event.url.split('/')[1];
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this._assetService.setApiEndpoint(environment.apiBaseUrl + '/api/assets/byId/');

        this.account = this._msalService.instance.getAllAccounts()[0];

        Sentry.setUser({ username: this.account.name });

        this.initialLoadingService.loadInitialDataAsync().then(() => {
            this._assetService.setTenantId(this.selectionService.selectedTenant?.id ?? 'na');
            this.#startGlobalSubscriptions();
            this._notificationService.initialize();
        });
    }

    ngOnDestroy(): void {
        this._routerEvents?.unsubscribe();
        this.#userRoleSubscription?.unsubscribe();
    }

    // #endregion Init

    // #region Public Methods

    signOut() {
        this._msalService.logout();
    }

    clickTab(event: any) {
        //console.log(event);
    }

    async debugCopyGraphQlHeaders() {
        const result = await firstValueFrom(
            this._msalService.acquireTokenSilent({
                scopes: ['openid', 'profile'],
                account: this.account,
            })
        );

        const headers = `{
  "Gat-Tenant-Id": "41d1bba1-a637-4328-8414-14401e25d520",
  "Gat-Date": "${new Date().toISOString()}",
  "Gat-Zone": "${DateTime.local().zone.name}",
  "Authorization": "Bearer ${result.accessToken}"
}`;
        this.clipboard.copy(headers);
        console.log(result);
        console.log(headers);
    }

    async onTenantButtonClicked(tenant: TenantOutput) {
        await this._userConfigService.updateAsync({
            tenantId: tenant.id,
        });
        this.selectionService.selectedTenant = tenant;
        this._assetService.setTenantId(tenant.id);
        this.router.navigateByUrl('/home');
        this.activeTabRouterLink = 'home';
    }

    goTo(event: MouseEvent, route: string) {
        this.router.navigateByUrl(route);
    }

    // #endregion Public Methods

    async #startGlobalSubscriptions() {
        this.#userRoleSubscription?.unsubscribe();

        this.#userRoleSubscription = this._apollo
            .subscribe<UserRoleSubscriptionRoot>({
                query: gql`
          subscription UserRoleSubscription {
            userRoleSubscription {
              data {
                userOid
                action
                roleId
              }
            }
          }
        `,
            })
            .subscribe((result) => {
                console.log('user notification triggered');
                const notificationData = result.data?.userRoleSubscription;
                if (!notificationData?.data) {
                    return;
                }

                // Add to notification feed.
                //this.notificationService.add(notificationData);

                const toastMessages: string[] = [];
                for (const change of notificationData.data) {
                    let toastMessage = '';
                    if (change.action === 'created') {
                        toastMessage += 'ADDED ';
                    } else {
                        toastMessage += 'REMOVED ';
                    }

                    toastMessage += `'${roles.get(change.roleId)}'`;

                    toastMessages.push(toastMessage);
                }

                const message = 'Your user roles were changed by a tenant admin.<br>';
                this._toastService.info(message + toastMessages.join('<br>'), 'User Role Changes');

                if (this.#isRoleUpdatesDialogOpen) {
                    return; // do nothing
                }

                // Analyze what has been notified.
                if (notificationData.data?.find((x) => x.roleId !== RoleId.ASSET_USER)) {
                    // A relevant role has changed for the user. Show dialog to force a reload
                    // of the app.
                    this.#isRoleUpdatesDialogOpen = true;

                    const dialog = this._matDialog.open(RoleUpdatesDialogComponent, {
                        autoFocus: false,
                    });

                    dialog.afterClosed().subscribe(() => {
                        location.replace(location.origin);
                    });
                }
            });
    }

    onLeftDrawerClose(drawer: MatDrawer) {
        drawer.toggle();

        (document.activeElement as HTMLElement)?.blur();
    }
}
