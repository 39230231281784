import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";

export interface AuditEditDialogData {
  name: string;
  description: string;
}
@Component({
  selector: 'app-audit-edit-dialog',
  templateUrl: './audit-edit-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatInputModule,
    FormsModule,
    MatButtonModule
  ],
  styleUrls: ['./audit-edit-dialog.component.scss']
})
export class AuditEditDialogComponent {
  constructor(private matDialogRef : MatDialogRef<AuditEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AuditEditDialogData){}
  update() {
    this.matDialogRef.close(this.data);
  }

  cancel() {
    this.matDialogRef.close();
  }
}
