<h2 matDialogTitle>Edit audit</h2>

<mat-dialog-content>

  <div class="flex-column gap-normal form-field-density-1">
    <mat-form-field appearance="fill" subscriptSizing="dynamic">
      <mat-label>Name</mat-label>
      <input
        matInput
        required
        type="text"
        [(ngModel)]="data.name"
      />
    </mat-form-field>

    <mat-form-field appearance="fill" subscriptSizing="dynamic">
      <mat-label>Description</mat-label>
      <textarea
        rows="3"
        [cdkTextareaAutosize]="true"
        matInput
        type="text"
        [(ngModel)]="data.description"
      ></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancel()">
    Cancel
  </button>

  <button
    (click)="update()"
    mat-flat-button
    color="primary"
  >
    Update
  </button>
</mat-dialog-actions>
