<h2 mat-dialog-title>
    {{ isEdit ? 'Edit' : 'Create' }}
    Asset Reminder for
    <span class="color-primary-400 font-weight-500">
        {{ data.assetId }}
    </span>
</h2>
<mat-dialog-content>
    <lib-spinner *ngIf="loading; else notLoading"></lib-spinner>

    <ng-template #notLoading>
        <div class="flex-column form-field-density-1">
            <mat-form-field appearance="fill" subscriptSizing="dynamic" class="mb-normal">
                <mat-label>Reminder Date</mat-label>
                <input matInput [matDatepicker]="datepicker" [(ngModel)]="selectedReminderDate" [min]="optMinDate"
                    [max]="optMaxDate" />
                <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" class="mb-normal">
                <mat-label>Reminder Description</mat-label>
                <textarea type="text" matInput [(ngModel)]="selectedComment" rows="2"
                    [cdkTextareaAutosize]="true"></textarea>
            </mat-form-field>

            <div>
                <lib-user-select *ngIf="selectedResponsibleOid" class="mt-normal" label="Additional Receiver"
                    appearance="fill" [(oId)]="selectedResponsibleOid"></lib-user-select>
                <span>The Asset Reminder is automatically sent to all Asset Managers of this tenant, but can also be
                    sent to an <b>additional</b> person (e.g. Asset Responsible, Project Manager)</span>
            </div>
        </div>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button [disabled]="loading" (click)="closeDialog()">
        Cancel
    </button>
    <button mat-flat-button color="primary" [disabled]="loading || !canSave" (click)="saveChanges()">
        Save
    </button>
</mat-dialog-actions>