import { CreateAssetReminderMutationRoot, UpdateAssetReminderMutationRoot } from 'projects/shared/src/lib/graphql/crud/assetReminder';
import { AssetReminderInputCreate } from 'projects/shared/src/lib/graphql/input/create/assetReminderInputCreate';
import { AssetReminderInputUpdate } from 'projects/shared/src/lib/graphql/input/update/assetReminderInputUpdate';
import { AssetReminderOutput } from 'projects/shared/src/lib/graphql/output/assetReminderOutput';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-create-asset-reminders-dialog',
    templateUrl: './create-asset-reminders-dialog.component.html',
    styleUrls: ['./create-asset-reminders-dialog.component.scss']
})
export class CreateAssetRemindersDialogComponent implements OnInit {
    isEdit = false;
    loading = false;
    optMinDate!: Date;
    optMaxDate!: Date;
    selectedReminderDate!: Date;
    selectedResponsibleOid?: string;
    selectedComment?: string | null;

    constructor(
        private _dialogRef: MatDialogRef<CreateAssetRemindersDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { assetId: string; tenantId: string; reminder?: AssetReminderOutput | null; },
        @Inject(MAT_DATE_LOCALE) public locale: string,
        private _toastService: DesktopToastService,
        private _apollo: Apollo,
    ) { }

    ngOnInit(): void {
        this.loading = true;
        try {
            // Reminder Date range not in the past and not more than 10 years in the future
            this.optMinDate = new Date();
            this.optMaxDate = new Date(new Date().getFullYear() + 10, 11, 31);

            if (this.data.reminder) {
                this.isEdit = true;
                this.selectedReminderDate = this.data.reminder.reminderDate;
                this.selectedComment = this.data.reminder.comment;
            } else {
                this.isEdit = false;
                this.selectedReminderDate = this.optMinDate;
                this.selectedComment = null;
            }
        } catch (error) {
            this._toastService.error(new CatchError(error).message, 'ERROR');
            this._dialogRef.close(undefined);
        } finally {
            this.loading = false;
        }
    }

    get canSave(): boolean {
        return (this.selectedReminderDate !== null && this.selectedReminderDate !== undefined);
    }

    async saveChanges() {
        if (this.canSave) {
            // Check if the this.selectedReminderDate is a js Date or luxon DateTime
            // (convert to luxon DateTime if it is a js Date - and validate)
            let selectedDate: DateTime = (this.selectedReminderDate instanceof Date)
                ? DateTime.fromJSDate(this.selectedReminderDate)
                : DateTime.fromISO(this.selectedReminderDate);

            if (!this.selectedReminderDate || !selectedDate.isValid
                || selectedDate > DateTime.fromJSDate(this.optMaxDate)
                || selectedDate < DateTime.fromJSDate(this.optMinDate)) {
                this._toastService.error('Invalid date', 'ERROR');
                return;
            }

            try {
                let assetReminderResult: AssetReminderOutput | undefined;
                if (!this.isEdit) {
                    // Create a new Reminder and save it
                    const result = await firstValueFrom(
                        this._apollo.mutate<CreateAssetReminderMutationRoot>({
                            mutation: gql`
                            mutation CreateAssetReminder($data: AssetReminderInputCreate!) {
                                createAssetReminder(data: $data) {
                                    id
                                    tenantId
                                    tenantAssetId
                                    reminderDate
                                    additionalReceiver
                                    reminderSent
                                    comment
                                    createdAt
                                    createdBy
                                }
                            }`,
                            variables: {
                                data: ({
                                    tenantId: this.data.tenantId,
                                    tenantAssetId: this.data.assetId,
                                    reminderDate: selectedDate.toJSDate(),
                                    additionalReceiver: this.selectedResponsibleOid,
                                    comment: this.selectedComment,
                                } as AssetReminderInputCreate)
                            },
                            fetchPolicy: 'network-only',
                        }));

                    assetReminderResult = result.data?.createAssetReminder;
                } else {
                    const result = await firstValueFrom(
                        this._apollo.mutate<UpdateAssetReminderMutationRoot>({
                            mutation: gql`
                            mutation UpdateAssetReminder($id: String!, $data: AssetReminderInputUpdate!) {
                                updateAssetReminder(id: $id, data: $data) {
                                    id
                                    tenantId
                                    tenantAssetId
                                    reminderDate
                                    additionalReceiver
                                    reminderSent
                                    comment
                                    createdAt
                                    createdBy
                                }
                            }`,
                            variables: {
                                id: this.data.reminder?.id,
                                data: ({
                                    reminderDate: selectedDate.toJSDate(),
                                    additionalReceiver: this.selectedResponsibleOid,
                                    comment: this.selectedComment,
                                } as AssetReminderInputUpdate)
                            },
                            fetchPolicy: 'network-only',
                        }));

                    assetReminderResult = result.data?.updateAssetReminder;
                }

                // Close the dialog and return the new/updated AssetReminder Id
                this._dialogRef.close(assetReminderResult);
            } catch (error) {
                this._toastService.error(new CatchError(error).message, 'ERROR');
                this._dialogRef.close(undefined);
            } finally {
                this.loading = false;
            }
        }
    }

    closeDialog() {
        this._dialogRef.close(undefined);
    }

}
