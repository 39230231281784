import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDialog} from '@angular/material/dialog';
import {Apollo, gql} from 'apollo-angular';
import {LocaleService} from 'projects/shared/src/lib/services/locale.service';
import {firstValueFrom, lastValueFrom} from 'rxjs';
import {DesktopToastService} from '../../../services/desktop-toast.service';
import {
  AssetsTableComponent
} from '../../../components/assets/assets-and-plans/assets-table/assets-table.component';
import {LocalEventService,} from '../../../services/local-event.service';
import {SelectionService} from '../../../services/selection.service';
import {
  InventoryStatisticsData,
  InventoryStatisticsDialogComponent,
} from '../../../component-dialogs/inventory-statistics-dialog/inventory-statistics-dialog.component';
import {ConfirmService} from '../../../services/confirm.service';
import {
  AuditEditDialogComponent,
  AuditEditDialogData
} from "../../../component-dialogs/audit-edit-dialog/audit-edit-dialog.component";

type Content = 'audits';

export interface UserRoleAuditSnapshotOutput {
  id: string;
  tenantId: string;
  name: string;
  description?: string | null;
  completedAt?: Date | null;
  completedBy?: string | null;
  createdAt: Date;
  createdBy: string;
}

@Component({
  selector: 'security-app-ag-sidebar',
  templateUrl: './security-ag-sidebar.component.html',
  styleUrls: ['./security-ag-sidebar.component.scss'],
})
export class SecurityAgSidebarComponent implements OnInit {
  context: AssetsTableComponent | undefined;

  showContent: Content | undefined;
  audits: UserRoleAuditSnapshotOutput[] = [];
  auditSelected: UserRoleAuditSnapshotOutput | undefined;
  auditDragSituation = new Map<string, number>();
  auditActivity = new Map<string, boolean>();

  constructor(
    public apollo: Apollo,
    public localeService: LocaleService,
    public toastService: DesktopToastService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    private matDialog: MatDialog,
    private localEventService: LocalEventService,
    private selectionService: SelectionService,
    private confirmService: ConfirmService
  ) {
  }

  ngOnInit(): void {
  }

  async onNavigation(showContent: Content | undefined) {
    console.log('onNavigation', showContent)
    if (this.showContent === showContent) {
      this.showContent = undefined;
      return;
    }
    await this.loadAudits()
    this.showContent = showContent;
  }

  private async loadAudits() {
    const query = gql`
      query UserRoleAudits {
        userRoleAudits {
              id
              tenantId
              name
              description
              completedAt
              completedBy
              createdAt
              createdBy
        }
      }
    `;
    const resp = await lastValueFrom(this.apollo.query<{
      userRoleAudits: UserRoleAuditSnapshotOutput[]
    }>({query, fetchPolicy: 'no-cache'}));
    //make the userRoleAudits a copy of the response and not readonly
    this.audits = resp.data.userRoleAudits.map(a => ({...a}))
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());


  }

  onAuditClick(inventory: UserRoleAuditSnapshotOutput) {
    // if (this.auditSelected?.id === inventory.id) {
    //   this.auditSelected = undefined;
    //   this.filterInventory.emit(undefined);
    //   return;
    // }
    //
    // this.auditSelected = inventory;
    // this.filterInventory.emit(inventory);
  }

  onAuditEditClick(event: MouseEvent, audit: UserRoleAuditSnapshotOutput) {
    event.stopPropagation();

    const data: AuditEditDialogData = {
      name: audit.name,
      description: audit.description ?? ''
    };

    const dialog = this.matDialog.open(AuditEditDialogComponent, {
      autoFocus: false,
      data,
      minWidth: 400,
    });

    dialog.afterClosed().subscribe(async (result: AuditEditDialogData) => {
      if (!result) {
        return
      }
      const auditCopy = {...audit}
      auditCopy.description = result.description;
      auditCopy.name = result.name;
      await this.updateAudit(auditCopy)
    });
  }

  private async updateAudit(audit: UserRoleAuditSnapshotOutput) {
    try {
      const mutation = gql`
          mutation UpdateUserRoleAudit($id: String!, $data: UserRoleAuditInputUpdate!) {
            updateUserRoleAudit(id: $id, data: $data) {
              name
              description
            }
          }
      `
      await lastValueFrom(this.apollo.mutate<{ updateUserRoleAudit: UserRoleAuditSnapshotOutput }>({
        mutation,
        variables: {
          id: audit.id,
          data: {
            name: audit.name,
            description: audit.description
          }
        }
      }))
      let find = this.audits.find(a => a.id === audit.id);
      find!.name = audit.name
      find!.description = audit.description
      this.toastService.info('Audit updated')
    } catch (e) {
      console.error(e)
      this.toastService.error('Error updating audit')
    }
  }

  async onAuditDeleteClick(event: MouseEvent, audit: UserRoleAuditSnapshotOutput) {
    event.stopPropagation();

    this.confirmService.open(
      'Are you sure?',
      'Do you really want to delete this audit and all related data?',
      async () => {
        const variables = {
          id: audit.id,
        };
        try {
          const resp = await firstValueFrom(
            this.apollo.mutate({
              mutation: gql`
                mutation DeleteUserRoleAudit($id: String!) {
                  deleteUserRoleAudit(id: $id) {
                    name
                  }
                }
              `,
              variables,
            })
          );
          this.audits = this.audits.filter((a) => a.id !== audit.id);
          this.toastService.info('Audit deleted');
        } catch (e) {
          console.error(e);
          this.toastService.error('Error deleting audit');
        }
      }
    );
  }

  onAuditCompleteClick(event: MouseEvent, audit: UserRoleAuditSnapshotOutput) {
    event.stopPropagation();
    this.confirmService.open(
      'Are you sure?',
      `Setting an audit to 'completed' cannot be revoked.`,
      async () => {
        const variables = {
          id: audit.id,
        };

        try {
          const resp = await firstValueFrom(
            this.apollo.mutate<{ completeUserRoleAudit: UserRoleAuditSnapshotOutput }>({
              mutation: gql`
                mutation CompleteUserRoleAudit($id: String!) {
                  completeUserRoleAudit(id: $id) {
                    completedAt
                    completedBy
                  }
                }
              `,
              variables,
            })
          );
          console.log(resp)
          audit.completedAt = resp.data?.completeUserRoleAudit?.completedAt;
          audit.completedBy = resp.data?.completeUserRoleAudit?.completedBy;
          this.toastService.info('Audit completed');
        } catch (e) {
          console.error(e);
          this.toastService.error('Error completing audit');
        }
      }
    );
  }

  onAuditStatisticsClick(event: MouseEvent, inventory: UserRoleAuditSnapshotOutput) {
    event.stopPropagation();

    const data: InventoryStatisticsData = {
      inventoryId: inventory.id,
      inventoryName: inventory.name,
    };

    const dialog = this.matDialog.open(InventoryStatisticsDialogComponent, {
      data,
      autoFocus: false,
      minWidth: 800,
    });
  }
}
