<!-- ####### -->
<!-- POPOVER -->
<!-- ####### -->
<mtx-popover #popover="mtxPopover">
    <ng-template mtxPopoverContent let-currentPlan="currentPlan" let-futurePlans="futurePlans">
        <div class="flex-column font-size-14">
            <span>
                This asset is included in the following plans (current and future only):
            </span>
            <ng-container *ngIf="currentPlan">
                <span class="mt-small font-weight-500 green">current</span>
                <div class="plans-grid">
                    <div>{{ currentPlan.planStart | date : getDatePipeString() }}</div>
                    <div>&minus;</div>
                    <div>{{ currentPlan.planEnd | date : getDatePipeString() }}</div>
                    <div class="ml-small">{{ currentPlan.name }}</div>
                </div>
            </ng-container>

            <ng-container *ngIf="futurePlans.length > 0">
                <span class="mt-small font-weight-500 orange">future</span>
                <div class="plans-grid">
                    <ng-container *ngFor="let plan of futurePlans">
                        <div>{{ plan.planStart | date : getDatePipeString() }}</div>
                        <div>&minus;</div>
                        <div>{{ plan.planEnd | date : getDatePipeString() }}</div>
                        <div class="ml-small">{{ plan.name }}</div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </ng-template>
</mtx-popover>

<!-- ######### -->
<!-- INVENTORY -->
<!-- ######### -->
<div *ngIf="isOnActiveInventories" class="inventory" [class.asset-confirmed]="assetIsConfirmed"
    #inventoryPopoverTrigger="mtxPopoverTrigger" [mtxPopoverTriggerFor]="inventoryPopover">
    <span>I</span>
    <mtx-popover #inventoryPopover="mtxPopover">
        <ng-template mtxPopoverContent>
            <div class="flex-column" style="padding: 8px">
                <div class="flex-row gap-large">
                    <span class="font-weight-500">
                        {{
                        unfinishedInventories.length === 1 ? "INVENTORY" : "INVENTORIES"
                        }}
                    </span>
                    <div class="flex-grow-1"></div>
                    <span class="color-warn">ACTIVE</span>
                </div>

                <div class="mt-normal mb-normal">
                    <mat-divider></mat-divider>
                </div>

                <lib-spinner *ngIf="getUnfinishedInventoriesInProgress; else notLoading"></lib-spinner>

                <ng-template #notLoading>
                    <div *ngFor="let inventory of unfinishedInventories; let i = index" class="flex-column gap-tiny"
                        [class.mt-normal]="i > 0">
                        <div class="flex-row align-items-center gap-normal">
                            <span>{{ inventory.name }}</span>
                            <span class="font-size-12">
                                ({{ inventory.noOfConfirmedAssets }} /
                                {{ inventory.noOfOverallAssets }})
                            </span>
                            <ng-container *ngIf="inventoryBooked.get(inventory.id); else notYetConfirmed">
                                <div class="font-size-12 color-green flex-row align-items-center gap-small">
                                    <span>asset confirmed</span>
                                    <span>at</span>
                                    <span>
                                        {{
                                        inventoryAssets.get(inventory.id + "_" + data.id)
                                        ?.inventoryAsset?.confirmedAt
                                        | date : localeService.datetimePipeString(locale)
                                        }}
                                    </span>
                                    <span>by</span>
                                    <lib-user [oId]="
                      inventoryAssets.get(inventory.id + '_' + data.id)
                        ?.inventoryAsset?.confirmedBy ?? 'na'
                    " variant="phone" [inheritAppearance]="true"></lib-user>
                                </div>
                            </ng-container>
                            <ng-template #notYetConfirmed>
                                <span class="font-size-12 blue"> asset not yet confirmed </span>
                            </ng-template>
                        </div>

                        <span *ngIf="inventory.description" class="font-size-12" style="opacity: 0.87">
                            {{ inventory.description }}
                        </span>

                        <div class="flex-row align-items-center gap-normal nowrap font-size-12">
                            <span>
                                {{
                                inventory.createdAt
                                | date : localeService.datetimePipeString(locale)
                                }}
                            </span>
                            <lib-user [oId]="inventory.createdBy" variant="phone" [inheritAppearance]="true"></lib-user>
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </mtx-popover>
</div>

<!-- ########################### -->
<!-- ON PLAN (current or future) -->
<!-- ########################### -->
<div *ngIf="currentPlan || futurePlans.length > 0" class="flex-row align-items-center gap-small cursor-pointer"
    style="width: fit-content" #popoverTrigger="mtxPopoverTrigger" [mtxPopoverTriggerFor]="popover"
    [mtxPopoverTriggerData]="{
    currentPlan: currentPlan,
    futurePlans: futurePlans
  }">
    <span *ngIf="currentPlan" class="plan-char green">P</span>

    <span *ngIf="futurePlans.length > 0" class="plan-char orange">P</span>
</div>

<!-- ##########-->
<!-- # DEFECT #-->
<!-- ##########-->
<div class="flex-row align-items-center cursor-pointer" *ngIf="data?.defectState > 1 || data?.defectType === 1"
    #popoverTrigger="mtxPopoverTrigger" [mtxPopoverTriggerFor]="popover" [mtxPopoverTriggerData]="{ context: this }">
    <span *ngIf="data?.defectType === 1" class="material-symbols-outlined fill font-size-16 color-primary-400"
        style="margin-bottom: 4px">
        star
    </span>

    <span *ngIf="data?.defectState === 2" class="defect-char"> D </span>

    <div *ngIf="data?.defectState === 3" class="flex-column align-items-center" style="margin-top: -3px">
        <span class="defect-100-char">D</span>
        <span class="defect-100-sub">100</span>
    </div>

    <span *ngIf="data?.defectState === 4" class="frozen-char"> F </span>

    <mtx-popover #popover="mtxPopover" [position]="['below', 'center']">
        <ng-template mtxPopoverContent let-context="context">
            <div class="flex-column" style="padding: 8px">
                <div class="flex-row gap-normal">
                    <div class="flex-column">
                        <ng-container *ngIf="context.data.defectState === 4">
                            <span class="font-weight-500">FROZEN</span>
                            <span class="small-text">frozen</span>
                        </ng-container>
                        <ng-container *ngIf="context.data.defectState === 3">
                            <span class="font-weight-500">DEFECT 100</span>
                            <span class="small-text">nonfunctional</span>
                        </ng-container>
                        <ng-container *ngIf="context.data.defectState === 2">
                            <span class="font-weight-500">DEFECT</span>
                            <span class="small-text">partially functional</span>
                        </ng-container>
                        <ng-container *ngIf="context.data.defectState === 1">
                            <span class="font-weight-500">OK</span>
                            <span class="small-text">fully functional</span>
                        </ng-container>
                    </div>
                    <div class="flex-grow-1"></div>

                    <div *ngIf="context.data.defectType === 1" class="color-warn-400">
                        UNVERIFIED
                    </div>
                    <div *ngIf="context.data.defectType === 2" class="color-green">
                        VERIFIED
                    </div>
                </div>

                <div class="mt-normal flex-row gap-small">
                    <button mat-flat-button style="background-color: #f0f0f0; font-weight: 400" (click)="
              reportDefect(context.data.id, context.data.defectType === 1)
            ">
                        {{
                        context.data.defectType === 1 ? "Verify Latest" : "Create Record"
                        }}
                    </button>
                </div>

                <!-- TODO -->
                <div class="mt-normal mb-normal">
                    <mat-divider></mat-divider>
                </div>

                <lib-spinner *ngIf="context.getAssetDefectsInProgress; else finishedLoading"></lib-spinner>

                <ng-template #finishedLoading>
                    <div class="defect-item">
                        <ng-container *ngFor="let assetDefect of context.assetDefects">
                            <!-- LEFT -->
                            <div class="flex-column small-text">
                                <span>
                                    {{
                                    assetDefect.timestamp
                                    | date : localeService.datetimePipeString(locale)
                                    }}
                                </span>
                                <span [class.color-green]="assetDefect.defectTypeId === 2">
                                    {{ assetDefect.defectTypeId === 1 ? "reported" : "verified" }}
                                </span>
                                <span *ngIf="assetDefect.defectStateId === 1" class="flex-row gap-tiny"
                                    style="white-space: nowrap">
                                    <span class="font-weight-500">OK</span>
                                    <span> - fully functional</span>
                                </span>
                                <span *ngIf="assetDefect.defectStateId === 2" class="flex-row gap-tiny"
                                    style="white-space: nowrap">
                                    <span class="font-weight-500">DEFECT</span>
                                    <span> - partially functional</span>
                                </span>
                                <span *ngIf="assetDefect.defectStateId === 3" class="flex-row gap-tiny"
                                    style="white-space: nowrap">
                                    <span class="font-weight-500">DEFECT 100</span>
                                    <span> - nonfunctional</span>
                                </span>
                                <span *ngIf="assetDefect.defectStateId === 4" class="flex-row gap-tiny"
                                    style="white-space: nowrap">
                                    <span class="font-weight-500">FROZEN</span>
                                    <span> - frozen</span>
                                </span>
                            </div>

                            <!-- RIGHT -->
                            <div class="flex-column small-text">
                                <lib-user [oId]="assetDefect.userOid" variant="phone"
                                    [inheritAppearance]="true"></lib-user>
                                <span>{{ assetDefect.comment }}</span>
                            </div>
                        </ng-container>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </mtx-popover>
</div>

<!-- MISSING -->
<div *ngIf="isMissing" class="missing-char" matTooltip="Asset is MISSING" matTooltipPosition="above">
    M
</div>

<!-- UPCOMING REMINDER -->
<div *ngIf="(hasUpcomingReminder > 0)">
    <span *ngIf="(hasUpcomingReminder == 1)" class="reminder-char-dark"
        matTooltip="Asset has an upcoming Reminder in the next 7 days" matTooltipPosition="above">R</span>
    <span *ngIf="(hasUpcomingReminder == 2)" class="reminder-char-light" matTooltip="Asset has an upcoming Reminder"
        matTooltipPosition="above">R</span>
</div>