<h2 mat-dialog-title>
    Asset REMINDERS for
    <span class="color-primary-400 font-weight-500">
        {{ data.assetId }}
    </span>
</h2>

<mat-dialog-content>
    <lib-spinner *ngIf="loading; else notLoading"></lib-spinner>

    <ng-template #notLoading>
        <div class="flex-column">
            <span *ngIf="assetReminders.length === 0; else hasReminders">No reminder records available.</span>
            <ng-template #hasReminders>
                <table mat-table [dataSource]="assetReminders" class="gat-table">
                    <ng-container matColumnDef="reminderDate">
                        <th mat-header-cell *matHeaderCellDef>Reminder Date</th>
                        <td mat-cell *matCellDef="let reminder">
                            {{ reminder.reminderDate | date : localeService.datetimePipeString(locale) }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="reminderSent">
                        <th mat-header-cell *matHeaderCellDef>Reminder Sent</th>
                        <td mat-cell *matCellDef="let reminder">{{ (reminder.reminderSent ? "Yes" : "No") }}</td>
                    </ng-container>
                    <ng-container matColumnDef="comment">
                        <th mat-header-cell *matHeaderCellDef>Comment</th>
                        <td mat-cell *matCellDef="let reminder">{{ reminder.comment }}</td>
                    </ng-container>
                    <ng-container matColumnDef="additionalReceiver">
                        <th mat-header-cell *matHeaderCellDef>Additional Receiver</th>
                        <td mat-cell *matCellDef="let reminder">
                            <lib-user *ngIf="reminder.additionalReceiver" [oId]="reminder.additionalReceiver"
                                style="white-space: nowrap"></lib-user>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="createdBy">
                        <th mat-header-cell *matHeaderCellDef>Created By</th>
                        <td mat-cell *matCellDef="let reminder">
                            <lib-user *ngIf="reminder.createdBy" [oId]="reminder.createdBy"
                                style="white-space: nowrap"></lib-user>
                        </td>
                    </ng-container>
                    <!-- Actions delete and edit -->
                    <!-- Enable only, if not sent -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let reminder">
                            <button mat-icon-button [disabled]="reminder.reminderSent" (click)="editReminder(reminder)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button [disabled]="reminder.reminderSent"
                                (click)="deleteAssetReminderClick(reminder)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

            </ng-template>
        </div>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button (click)="closeDialog()">Cancel</button>
</mat-dialog-actions>