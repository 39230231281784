<mtx-split style="height: 100%" direction="vertical" [gutterSize]="8">
  <!-- TOP PANE -->
  <mtx-split-pane [order]="1" [size]="40">
    <div class="pane-container-1" [class.zen]="!selectedUserConfiguration">
      <!-- Column 1 -->
      <div class="actions">
        <lib-user-select
          [(oId)]="selectedOId"
          style="width: 400px"
        ></lib-user-select>

        <ng-container *ngIf="selectedUserConfiguration">
          <button
            [disabled]="!selectedUserConfiguration.isChanged"
            mat-flat-button
            color="warn"
            (click)="onClickApply()"
            class="width-100"
            style="min-height: 56px"
          >
            APPLY
          </button>
          <button
            [disabled]="!selectedUserConfiguration.isChanged"
            (click)="selectedUserConfiguration.reset()"
            mat-stroked-button
            color="primary"
            class="width-100"
            style="min-height: 56px"
          >
            REJECT
          </button>
        </ng-container>
      </div>

      <!-- Column 2 -->
      <div *ngIf="selectedUserConfiguration" class="user-configuration-grid">
        <!-- Column 2.1 -->
        <div class="flex-column">
          <span class="mat-h1" style="margin-left: 10px">Roles</span>
          <mat-checkbox
            [disabled]="true"
            color="warn"
            [(ngModel)]="selectedUserConfiguration.isTenantOwner"
          >
            Tenant Owner
          </mat-checkbox>
          <mat-checkbox
            [disabled]="true"
            color="warn"
            [(ngModel)]="selectedUserConfiguration.isTenantAdmin"
          >
            Tenant Admin
          </mat-checkbox>
          <mat-checkbox
            color="warn"
            [(ngModel)]="selectedUserConfiguration.isAssetArchitect"
          >
            Asset Architect
          </mat-checkbox>
          <mat-checkbox
            color="warn"
            [(ngModel)]="selectedUserConfiguration.isAssetManager"
          >
            Asset Manager
          </mat-checkbox>

          <mat-checkbox
            color="warn"
            [(ngModel)]="selectedUserConfiguration.isAssetReader"
          >
            Asset Reader
          </mat-checkbox>

          <mat-checkbox
            color="warn"
            [(ngModel)]="selectedUserConfiguration.isAssetUser"
          >
            Asset User
          </mat-checkbox>
        </div>

        <!-- Column 2.2 -->
        <div class="flex-column" style="min-width: 400px">
          <span class="mat-h1">Asset Constraints</span>

          <span class="mat-body mb-small">
            Please note that the constraints only apply to the roles
            <span class="font-weight-500">Asset Manager</span> and
            <span class="font-weight-500">Asset User</span>.
          </span>

          <lib-spinner
            *ngIf="
              selectedUserConfiguration.loadingConstraints;
              else constraintsLoaded
            "
          ></lib-spinner>

          <ng-template #constraintsLoaded>
            <span
              *ngIf="selectedUserConfiguration.constraints.length === 0"
              class="mat-body"
            >
              Currently, the user does not have any constraints configured.
            </span>

            <table
              *ngIf="selectedUserConfiguration.constraints.length > 0"
              class="form-field-density-1 constraints-table"
            >
              <tr>
                <th>Property</th>
                <th>Value</th>
                <th style="width: 50px"></th>
              </tr>
              <tr
                *ngFor="let constraint of selectedUserConfiguration.constraints"
              >
                <td>
                  <ng-container *ngIf="constraint.isNew">
                    <app-auto-complete-property-input
                      [properties]="properties"
                      [(selected)]="constraint.property"
                    ></app-auto-complete-property-input>
                  </ng-container>
                  <ng-container *ngIf="!constraint.isNew">
                    <span [class.line-through]="constraint.isDeleted">{{
                      constraint.property?.displayName
                    }}</span>
                  </ng-container>
                </td>

                <td>
                  <ng-container *ngIf="constraint.isNew">
                    <app-auto-complete-string-input
                      [disabled]="!constraint.property"
                      [values]="constraint.propertyValues"
                      [(selected)]="constraint.value"
                    ></app-auto-complete-string-input>
                  </ng-container>
                  <ng-container *ngIf="!constraint.isNew">
                    <span [class.line-through]="constraint.isDeleted">{{
                      constraint.value
                    }}</span>
                  </ng-container>
                </td>

                <td>
                  <button
                    mat-icon-button
                    (click)="deleteConstraint(constraint)"
                    class="delete-icon"
                  >
                    <mat-icon fontSet="material-symbols-outlined"
                      >delete</mat-icon
                    >
                  </button>
                </td>
              </tr>
            </table>

            <button
              (click)="addNewConstraint()"
              mat-stroked-button
              color="primary"
              class="mt-normal"
            >
              Add Constraint
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </mtx-split-pane>

  <!-- BOTTOM PANE -->
  <mtx-split-pane [order]="2" [size]="60">
    <div class="pane-container">
      <div class="flex-column height-100">
        <div class="security-table-header">
          <div class="section">ASSIGNED ROLES</div>

          <div class="flex-row gap-small mr-normal">
            <span># of users:</span>
            <span
              ><b>{{ overallNoOfUsers }}</b></span
            >
          </div>

          <div class="flex-row gap-small mr-normal">
            <span>Asset reader:</span>
            <span
            ><b>{{ noOfUsersInRoleAssetReader }}</b></span
            >
          </div>

          <div class="flex-row gap-small mr-normal">
            <span>Asset User:</span>
            <span
              ><b>{{ noOfUsersInRoleAssetUser }}</b></span
            >
          </div>

          <div class="flex-row gap-small mr-normal">
            <span>Asset Manager:</span>
            <span
              ><b>{{ noOfUsersInRoleAssetManager }}</b></span
            >
          </div>

          <div class="flex-row gap-small mr-normal">
            <span>Asset Architect:</span>
            <span
              ><b>{{ noOfUsersInRoleAssetArchitect }}</b></span
            >
          </div>

          <div class="flex-row gap-small mr-normal">
            <span>Tenant Admin:</span>
            <span
              ><b>{{ noOfUsersInRoleTenantAdmin }}</b></span
            >
          </div>

          <div class="flex-row gap-small mr-normal">
            <span>Tenant Owner:</span>
            <span
              ><b>{{ noOfUsersInRoleTenantOwner }}</b></span
            >
          </div>

          <div *ngIf="selectedUserRole" class="flex-grow-1 flex-row-reverse">
            <button
              mat-flat-button
              [disabled]="
                selectedUserRole.roleId === 1 ||
                selectedUserRole.roleId === 2 ||
                selectedUserRole.roleId === 6
              "
              color="warn"
              (click)="onClickDeleteUserRole(selectedUserRole)"
            >
              <div class="flex-row align-items-center gap-tiny">
                <mat-icon>delete</mat-icon>
                <span style="line-height: initial">Delete User Role</span>
              </div>
            </button>
          </div>
          <div class="table-actions-right-buttons">
            <button
              mat-flat-button
              color="accent"
              style="min-width: unset; padding: 0 8px"
              [matMenuTriggerFor]="moreActionsMenu"
            >
              <div class="flex-row align-items-center">
                <mat-icon fontSet="material-symbols-outlined">more_horiz</mat-icon>
              </div>
            </button>
          <mat-menu #moreActionsMenu="matMenu">
            <ng-template matMenuContent>
              <button mat-menu-item (click)="startAudit()">
                <mat-icon fontSet="material-symbols-outlined">assignment</mat-icon>
                <span>Start audit</span>
              </button>
            </ng-template>
          </mat-menu>
          </div>
        </div>
        <!-- Table -->
        <div class="table-wrapper">
          <ag-grid-angular
            style="height: 100%"
            class="ag-theme-alpine"
            [columnDefs]="colDefs"
            [defaultColDef]="defaultColDef"
            [rowClassRules]="rowClassRules"
            [rowData]="extUserRoles"
            [rowSelection]="'single'"
            [animateRows]="true"
            (gridReady)="onGridReady($event)"
            [rowHeight]="26"
            (selectionChanged)="onSelectionChanged($event)"
            [enableCellTextSelection]="true"
            [ensureDomOrder]="true"
          >
          </ag-grid-angular>
          <security-app-ag-sidebar #agSidebar>
  <!--                (restoreDefaultColumnOrder)="onRestoreDefaultColumnOrder()"-->
  <!--                (filterInventory)="onFilterInventory($event)"-->

          </security-app-ag-sidebar>
        </div>
      </div>

    </div>

  </mtx-split-pane>
</mtx-split>

<!-- (filterChanged)="onFilterChanged($event)"
(selectionChanged)="onSelectionChanged($event)" -->
