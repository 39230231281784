import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_TENANT_CONFIG = gql`
  fragment FullFragmentTenantConfig on TenantConfigOutput {
    id
    tenantId
    missingGracePeriod
    scheduleInventory
    scheduleUserRoleAudit
  }
`;
