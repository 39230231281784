import { ErrorHandler, NgModule, SecurityContext } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
    MsalModule,
    MsalService,
    MsalGuard,
    MsalInterceptor,
    MsalBroadcastService,
    MsalRedirectComponent,
    MsalInterceptorConfiguration,
    MsalGuardConfiguration,
    MSAL_INSTANCE,
    MSAL_GUARD_CONFIG,
    MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
    PublicClientApplication,
    InteractionType,
    BrowserCacheLocation,
    IPublicClientApplication,
    LogLevel,
    AuthenticationResult,
} from '@azure/msal-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PortalModule } from '@angular/cdk/portal';
import { AgGridModule } from 'ag-grid-angular';
import { QRCodeModule } from 'angularx-qrcode';
import { MarkdownModule } from 'ngx-markdown';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { MtxDrawerModule } from '@ng-matero/extensions/drawer';
import { MtxLuxonDatetimeModule } from '@ng-matero/extensions-luxon-adapter';
import { MtxPopoverModule } from '@ng-matero/extensions/popover';
import { MtxSplitModule } from '@ng-matero/extensions/split';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
    MAT_LUXON_DATE_ADAPTER_OPTIONS,
    MatLuxonDateModule,
} from '@angular/material-luxon-adapter';
import { MAT_LUXON_DATE_FORMATS, LuxonDateAdapter } from '@angular/material-luxon-adapter';

import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';

import * as Sentry from '@sentry/angular-ivy';

import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AppComponent } from './app.component';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { environment } from '../environments/environment';
import { TokenComponent } from './components/token/token.component';
import { TenantComponent } from './components/tenant/tenant.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { PrivacyStatementComponent } from './components/privacy-statement/privacy-statement.component';
import { ToolbarToggleComponent } from './component-helpers/toolbar-toggle/toolbar-toggle.component';
import { ToolbarToggleItemComponent } from './component-helpers/toolbar-toggle/toolbar-toggle-item/toolbar-toggle-item.component';
import { MySettingsComponent } from './component-helpers/my-settings/my-settings.component';
import { MyNotificationsComponent } from './component-helpers/my-notifications/my-notifications.component';
import { SelectionService } from './services/selection.service';
import { DesignComponent } from './components/design/design.component';
import { SharedModule, sleep } from 'projects/shared/src/public-api';
import { AssetComponent } from './components/design/asset/asset.component';
import { NewAssetDialogComponent } from './component-dialogs/new-asset-dialog/new-asset-dialog.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GlobalSystemComponent } from './components/design/global-system/global-system.component';
import { GlobalCustomComponent } from './components/design/global-custom/global-custom.component';
import { NewOptionDialogComponent } from './component-dialogs/new-option-dialog/new-option-dialog.component';
import { OptionComponent } from './components/design/option/option.component';
import { NewOptionItemsDialogComponent } from './component-dialogs/new-option-items-dialog/new-option-items-dialog.component';
import { ArrayOrderByPipe } from './pipes/orderBy';
import { TextButtonComponent } from './component-helpers/text-button/text-button.component';
import { RenameOptionItemDialogComponent } from './component-dialogs/rename-option-item-dialog/rename-option-item-dialog.component';
import { AssetsComponent } from './components/assets/assets.component';
import { ImportAssetsDialogComponent } from './component-dialogs/import-assets-dialog/import-assets-dialog.component';
import { FileDropComponent } from './component-helpers/file-drop/file-drop.component';
import { AssetsTableComponent } from './components/assets/assets-and-plans/assets-table/assets-table.component';
import { AssetsPlansComponent } from './components/assets/assets-and-plans/assets-plans/assets-plans.component';
import { AssetsPlanComponent } from './components/assets/assets-and-plans/assets-plan/assets-plan.component';
import { WindowComponent } from './component-helpers/window/window.component';
import { NewPlanDialogComponent } from './component-dialogs/new-plan-dialog/new-plan-dialog.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { AssetInfoRendererComponent } from './component-helpers/ag/asset-info-renderer/asset-info-renderer.component';
import { PlanInfoRendererComponent } from './component-helpers/ag/plan-info-renderer/plan-info-renderer.component';
import { CreateOrEditPlanStepActionDialogComponent } from './component-dialogs/create-or-edit-plan-step-action-dialog/create-or-edit-plan-step-action-dialog.component';
import { SpacePlusComponent } from './component-helpers/space-plus/space-plus.component';
import { AgSidebarComponent } from './component-helpers/ag/ag-sidebar/ag-sidebar.component';
import { AddAssetsToPlanDialogComponent } from './component-dialogs/add-assets-to-plan-dialog/add-assets-to-plan-dialog.component';
import { ConfirmDialogComponent } from './component-dialogs/confirm-dialog/confirm-dialog.component';
import { PlanStepActionsComponent } from './components/assets/assets-and-plans/assets-plan/plan-step-actions/plan-step-actions.component';
import { PlanStepActionsSummaryComponent } from './components/assets/assets-and-plans/assets-plan/plan-step-actions-summary/plan-step-actions-summary.component';
import { PlanStepAssetsComponent } from './components/assets/assets-and-plans/assets-plan/plan-step-assets/plan-step-assets.component';
import { ShowQrcodeDialogComponent } from './component-dialogs/show-qrcode-dialog/show-qrcode-dialog.component';
import { SecurityComponent } from './components/security/security.component';
import { UserRoleRendererComponent } from './component-helpers/ag/user-role-renderer/user-role-renderer.component';
import { ActionDetailsComponent } from './component-helpers/action-details/action-details.component';
import { LocationsComponent } from './components/design/locations/locations.component';
import { EditLocationComponent } from './component-helpers/edit-location/edit-location.component';
import { AddLocationComponent } from './component-helpers/add-location/add-location.component';
import { ShowAssetHistoryDialogComponent } from './component-dialogs/show-asset-history-dialog/show-asset-history-dialog.component';
import { DateTime, Info } from 'luxon';
import { AdjustPlanDurationDialogComponent } from './component-dialogs/adjust-plan-duration-dialog/adjust-plan-duration-dialog.component';
import { BookRealtimeActionDialogComponent } from './component-dialogs/book-realtime-action-dialog/book-realtime-action-dialog.component';
import { BookRealtimeActionComponent } from './component-helpers/book-realtime-action/book-realtime-action.component';
import { firstValueFrom } from 'rxjs';
import { RoleUpdatesDialogComponent } from './component-dialogs/role-updates-dialog/role-updates-dialog.component';
import { EditMultipleAssetsDialogComponent } from './component-dialogs/edit-multiple-assets-dialog/edit-multiple-assets-dialog.component';
import { EditMultipleAssetsDrawerComponent } from './component-drawers/edit-multiple-assets-drawer/edit-multiple-assets-drawer.component';
import { BookPlanStepActionDialogComponent } from './component-dialogs/book-plan-step-action-dialog/book-plan-step-action-dialog.component';
import { ActivityDialogComponent } from './component-dialogs/activity-dialog/activity-dialog.component';
import { InfoComponent } from './component-helpers/info/info.component';
import { FluidPanelComponent } from './component-helpers/fluid-panel/fluid-panel.component';
import { AssetsPlansLiveComponent } from './components/assets/assets-and-plans/assets-plans/assets-plans-live/assets-plans-live.component';
import { DesktopToastComponent } from './component-helpers/desktop-toast/desktop-toast.component';
import { SetAssetMissingDialogComponent } from './component-dialogs/set-asset-missing-dialog/set-asset-missing-dialog.component';
import { SubscriptionService } from './serices/subscription.service';
import { v4 } from 'uuid';
import { ReportDefectDialogComponent } from './component-dialogs/report-defect-dialog/report-defect-dialog.component';
import { ViewDefectsDialogComponent } from './component-dialogs/view-defects-dialog/view-defects-dialog.component';
import { AssetInfoComponent } from './component-helpers/asset-info/asset-info.component';
import { AutoCompletePropertyInputComponent } from './component-helpers/auto-complete/auto-complete-property-input/auto-complete-property-input.component';
import { AutoCompleteStringInputComponent } from './component-helpers/auto-complete/auto-complete-string-input/auto-complete-string-input.component';
import { ErrorDialogComponent } from './component-dialogs/error-dialog/error-dialog.component';
import { AssetsAndPlansComponent } from './components/assets/assets-and-plans/assets-and-plans.component';
import { ReturnToCustomerComponent } from './components/assets/return-to-customer/return-to-customer.component';
import { InventoryCreateDialogComponent } from './component-dialogs/inventory-create-dialog/inventory-create-dialog.component';
import { InventoryEditDialogComponent } from './component-dialogs/inventory-edit-dialog/inventory-edit-dialog.component';
import { AgCustomHeaderComponent } from './component-helpers/ag/ag-custom-header/ag-custom-header.component';
import { InventoryStatisticsDialogComponent } from './component-dialogs/inventory-statistics-dialog/inventory-statistics-dialog.component';
import { AssetsTableFilterComponent } from './components/assets/assets-and-plans/assets-table/assets-table-filter/assets-table-filter.component';
import { GatCardComponent } from './component-helpers/gat-card/gat-card.component';
import { GatCardHeaderComponent } from './component-helpers/gat-card/gat-card-header/gat-card-header.component';
import { GatCardActionsComponent } from './component-helpers/gat-card/gat-card-actions/gat-card-actions.component';
import { GatCardContentComponent } from './component-helpers/gat-card/gat-card-content/gat-card-content.component';
import { ReturnToCustomerCreateDialogComponent } from './component-dialogs/return-to-customer-create-dialog/return-to-customer-create-dialog.component';
import { ReturnToCustomerDetailsComponent } from './components/assets/return-to-customer/return-to-customer-details/return-to-customer-details.component';
import { HoverIconComponent } from './component-helpers/hover-icon/hover-icon.component';
import { NotReaderDirective } from './directives/not-reader.directive';
import { RestoreComponent } from './components/assets/restore/restore.component';
import { ButtonRendererComponent } from './component-helpers/ag/button-renderer/button-renderer.component';
import { RestoreDialogComponent } from './components/assets/restore/restore-dialog/restore-dialog.component';
import {
    SecurityAgSidebarComponent
} from "./component-helpers/ag/security-ag-sidebar/security-ag-sidebar.component";


const sessionId = v4();

const adjustedBaseUrl =
    environment.apiBaseUrl[environment.apiBaseUrl.length - 1] === '/'
        ? environment.apiBaseUrl.slice(0, environment.apiBaseUrl.length - 2)
        : environment.apiBaseUrl;

const apiUrl = adjustedBaseUrl + '/api';
const graphqlUrl = adjustedBaseUrl + '/graphql';

let wsUrl = adjustedBaseUrl + '/subscriptions';
wsUrl = wsUrl.replace('https://', 'wss://');
wsUrl = wsUrl.replace('http://', 'ws://');

const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
protectedResourceMap.set('https://graph.microsoft.com/v1.0/*', ['user.read.all']);

protectedResourceMap.set(`${apiUrl}/myUser/`, environment.apiScopes);
protectedResourceMap.set(`${apiUrl}/*`, environment.apiScopes);
protectedResourceMap.set(graphqlUrl, environment.apiScopes);

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.clientId,
            authority: environment.authority,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: window.location.origin + '/login',
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false, // set to true for IE 11. Remove this line to use Angular Universal
        },
        system: {
            allowNativeBroker: false, // Disables WAM Broker
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Error,
                piiLoggingEnabled: false,
            },
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,

        authRequest: {
            scopes: ['openid', 'profile', 'email', environment.apiScopes[0]],
        },
        loginFailedRoute: '/login-failed',
    };
}

const graphqlCache = new InMemoryCache({
    typePolicies: {
        OptionOutput: {
            fields: {
                optionItems: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
            },
        },
        PlanOutput: {
            fields: {
                planAssets: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
                planSteps: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
            },
        },
        PlanStepOutput: {
            fields: {
                planStepAssets: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
            },
        },
        Query: {
            fields: {
                planAssets: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
                plans: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
                planSteps: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
                planStepActions: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
                planStepAssets: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
                tenantActions: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
                userConstraintsByUser: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
                inventories: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
                returnToCustomerAssets: {
                    merge(existing, incoming, { mergeObjects }) {
                        return incoming;
                    },
                },
            },
        },
    },
});

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        LoginComponent,
        HomeComponent,
        TokenComponent,
        TenantComponent,
        TermsOfUseComponent,
        PrivacyStatementComponent,
        ToolbarToggleComponent,
        ToolbarToggleItemComponent,
        MySettingsComponent,
        MyNotificationsComponent,
        DesignComponent,
        AssetComponent,
        NewAssetDialogComponent,
        GlobalSystemComponent,
        GlobalCustomComponent,
        NewOptionDialogComponent,
        OptionComponent,
        NewOptionItemsDialogComponent,
        ArrayOrderByPipe,
        TextButtonComponent,
        RenameOptionItemDialogComponent,
        AssetsComponent,
        ImportAssetsDialogComponent,
        FileDropComponent,
        AssetsTableComponent,
        AssetsPlansComponent,
        AssetsPlanComponent,
        WindowComponent,
        NewPlanDialogComponent,
        AssetInfoRendererComponent,
        PlanInfoRendererComponent,
        CreateOrEditPlanStepActionDialogComponent,
        SpacePlusComponent,
        AgSidebarComponent,
        SecurityAgSidebarComponent,
        AddAssetsToPlanDialogComponent,
        ConfirmDialogComponent,
        PlanStepActionsComponent,
        PlanStepActionsSummaryComponent,
        PlanStepAssetsComponent,
        ShowQrcodeDialogComponent,
        SecurityComponent,
        UserRoleRendererComponent,
        ActionDetailsComponent,
        LocationsComponent,
        EditLocationComponent,
        AddLocationComponent,
        ShowAssetHistoryDialogComponent,
        AdjustPlanDurationDialogComponent,
        BookRealtimeActionDialogComponent,
        BookRealtimeActionComponent,
        RoleUpdatesDialogComponent,
        EditMultipleAssetsDialogComponent,
        EditMultipleAssetsDrawerComponent,
        BookPlanStepActionDialogComponent,
        ActivityDialogComponent,
        InfoComponent,
        FluidPanelComponent,
        AssetsPlansLiveComponent,
        DesktopToastComponent,
        SetAssetMissingDialogComponent,
        ReportDefectDialogComponent,
        ViewDefectsDialogComponent,
        AssetInfoComponent,
        AutoCompletePropertyInputComponent,
        AutoCompleteStringInputComponent,
        ErrorDialogComponent,
        AssetsAndPlansComponent,
        ReturnToCustomerComponent,
        InventoryCreateDialogComponent,
        InventoryEditDialogComponent,
        AgCustomHeaderComponent,
        InventoryStatisticsDialogComponent,
        AssetsTableFilterComponent,
        GatCardComponent,
        GatCardHeaderComponent,
        GatCardActionsComponent,
        GatCardContentComponent,
        ReturnToCustomerCreateDialogComponent,
        ReturnToCustomerDetailsComponent,
        HoverIconComponent,
        RestoreComponent,
        ButtonRendererComponent,
        RestoreDialogComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        ClipboardModule,
        ApolloModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        PortalModule,
        QRCodeModule,
        NotReaderDirective,

        MatLuxonDateModule,
        MtxLuxonDatetimeModule,

        MarkdownModule.forRoot({
            loader: HttpClientModule,
            sanitize: SecurityContext.NONE,
        }),

        NgxChartsModule,
        DragDropModule,

        MatAutocompleteModule,
        MatBadgeModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,

        AgGridModule,

        MtxDatetimepickerModule,
        MtxDrawerModule,
        MtxPopoverModule,
        MtxSplitModule,
    ],
    providers: [
        DatePipe,
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
        { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { firstDayOfWeek: 1 } },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: (
                httpLink: HttpLink,
                selectionService: SelectionService,
                msalService: MsalService,
                subscriptionService: SubscriptionService
            ) => {
                const auth = setContext((operation, context) => {
                    const tenantId = selectionService.selectedTenant?.id;
                    if (!tenantId) {
                        return {};
                    } else {
                        return {
                            headers: {
                                'Gat-Tenant-Id': tenantId,
                                'Gat-Date': new Date().toISOString(),
                                'Gat-Zone': DateTime.local().zone.name,
                                'Gat-Session-Id': sessionId,
                            },
                        };
                    }
                });

                const http = ApolloLink.from([
                    auth,
                    httpLink.create({
                        uri: graphqlUrl,
                    }),
                ]);

                const ws = new GraphQLWsLink(
                    createClient({
                        url: wsUrl,
                        connectionParams: async () => {
                            const tenantId = selectionService.selectedTenant?.id;
                            const accounts = msalService.instance.getAllAccounts();
                            const account = accounts[0];

                            let attempts = 0;
                            const maxAttempts = 10;
                            let result: AuthenticationResult | undefined;
                            do {
                                result = await firstValueFrom(
                                    msalService.acquireTokenSilent({
                                        scopes: ['openid', 'profile', 'email', environment.apiScopes[0]],
                                        account: account,
                                    })
                                );
                                // Check, if the token already has expired.
                                // This can happen, if the web site it visited after some time (old token has expired)
                                // and the regular GraphQL queries have to yet be processes to that a valid new token is available.

                                if (result.expiresOn && result.expiresOn.getTime() < Date.now()) {
                                    console.log('(Cached) Token has expired. Waiting for next attempt.');
                                    attempts++;
                                    await sleep(2000);
                                } else {
                                    break;
                                }
                            } while (attempts < maxAttempts);

                            const authorization = `Bearer ${result.accessToken}`;
                            return {
                                authorization,
                                gatTenantId: tenantId,
                            };
                        },
                        shouldRetry: () => {
                            return true;
                        },
                        on: {
                            connected: (socket) => {
                                //console.log(socket);
                                subscriptionService.online = true;
                            },
                            closed: (event) => {
                                console.log(event);
                                subscriptionService.online = false;
                            },
                            error: (error) => {
                                console.log(error);
                            },
                        },
                    })
                );

                const wsWithAuth = ApolloLink.from([auth, ws]);

                const link = split(
                    // split based on operation type
                    ({ query }) => {
                        const call = getMainDefinition(query);
                        return call.kind === 'OperationDefinition' && call.operation === 'subscription';
                    },
                    ws,
                    http
                );

                return {
                    cache: graphqlCache,
                    link,
                };
            },
            deps: [HttpLink, SelectionService, MsalService, SubscriptionService],
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({ showDialog: false, logErrors: true }),
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
    static sessionId = sessionId;
    static graphqlCache: InMemoryCache = graphqlCache;
}
