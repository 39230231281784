import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Apollo, gql } from 'apollo-angular';
import { PlansQueryArgs, PlansQueryRoot } from 'projects/shared/src/lib/graphql/crud/plan';
import { FULL_FRAGMENT_PLAN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlan';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';

import { TenantDefectOutput } from 'projects/shared/src/lib/graphql/output/tenantDefectOutput';
import {
    AssetDefectsQueryRoot,
    AssetDefectsQueryArgs,
} from 'projects/shared/src/lib/graphql/crud/tenantDefect';
import { firstValueFrom } from 'rxjs';
import { FULL_FRAGMENT_TENANT_DEFECT } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantDefect';
import {
    ReportDefectDialogComponent,
    ReportDefectDialogData,
} from '../../../component-dialogs/report-defect-dialog/report-defect-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AssetsTableComponent } from '../../../components/assets/assets-and-plans/assets-table/assets-table.component';
import {
    UnfinishedInventoriesByAssetQueryArgs,
    UnfinishedInventoriesByAssetQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/inventory';
import { InventoryOutput } from 'projects/shared/src/lib/graphql/output/inventoryOutput';
import { FULL_FRAGMENT_INVENTORY } from 'projects/shared/src/lib/graphql/fragments/fullFragmentInventory';
import { InventoryAssetLoader } from './asset-info-renderer.common';

@Component({
    selector: 'app-asset-info-renderer',
    templateUrl: './asset-info-renderer.component.html',
    styleUrls: ['./asset-info-renderer.component.scss'],
})
export class AssetInfoRendererComponent implements OnInit, ICellRendererAngularComp {
    currentPlan: PlanOutput | undefined;
    futurePlans: PlanOutput[] = [];
    data: any;
    plans: PlanOutput[] = [];
    context: AssetsTableComponent | undefined;
    inventoryBooked = new Map<string, boolean>();
    inventoryAssets = new Map<string, InventoryAssetLoader>();

    getAssetDefectsInProgress = false;
    getUnfinishedInventoriesInProgress = false;

    get unfinishedInventories(): InventoryOutput[] {
        if (typeof this.#unfinishedInventories === 'undefined') {
            this.#unfinishedInventories = [];
            this.#fetchUnfinishedInventories();
        }
        return this.#unfinishedInventories;
    }

    get assetDefects(): TenantDefectOutput[] {
        if (typeof this.#assetDefects === 'undefined') {
            this.#assetDefects = [];
            this.#fetchAssetDefects();
        }

        return this.#assetDefects;
    }

    get isDefect(): boolean {
        if (!this.data) {
            return false;
        }

        return this.data.defectState > 1;
    }

    get isMissing(): boolean {
        if (!this.data) {
            return false;
        }

        return this.data[this.context?.specialTableColumn_Missing?.field ?? 'na'] === true;
    }

    get hasUpcomingReminder(): number {
        if (!this.data) {
            return 0;
        }

        const rem = this.data[this.context?.specialTableColumn_UpcomingReminder?.field ?? 'na'];
        if (rem === undefined || rem === null || !rem)
            return 0;

        // Cast rem as Date and check if its within the next 7 days (week)
        return (new Date(rem).getTime() < new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
            ? 1     // within next week
            : 2;    // after next week
    }

    get isOnActiveInventories(): boolean {
        if (!this.data || !this.context) {
            return false;
        }

        return this.data['inventoryNames'];
    }

    get assetIsConfirmed(): boolean {
        if (!this.data || !this.context) {
            return false;
        }

        if (!this.context.filterInventoryId) {
            // No inventory filter set.
            // Return true is the asset is confirmed on ALL active inventories.
            for (const assetConfirmed of this.inventoryBooked.values()) {
                if (!assetConfirmed) {
                    return false;
                }
            }
            return true;
        }

        // We have an inventory filter set.
        // Return true if the asset is confirmed for the filtered inventory.
        return this.inventoryBooked.get(this.context.filterInventoryId) === true;
    }

    #assetDefects: TenantDefectOutput[] | undefined;
    #unfinishedInventories: InventoryOutput[] | undefined;

    constructor(
        private apollo: Apollo,
        @Inject(MAT_DATE_LOCALE) public locale: string,
        public localeService: LocaleService,
        private matDialog: MatDialog
    ) { }

    ngOnInit(): void {
        //
    }

    agInit(params: ICellRendererParams): void {
        if (typeof params.data === 'undefined') {
            return;
        }
        this.context = params.context as AssetsTableComponent;
        this.data = params.data;
        this.#loadPlansFromCache();
        this.#evaluateData(params);
        this.#evaluateInventoryData(params);
    }

    reportDefect(tenantAssetId: string, tryToVerify: boolean) {
        const data: ReportDefectDialogData = {
            assetId: tenantAssetId,
            tryToVerify,
        };

        const dialog = this.matDialog.open(ReportDefectDialogComponent, {
            autoFocus: false,
            minWidth: 680,
            maxWidth: 800,
            data,
        });
    }

    refresh(params: ICellRendererParams): boolean {
        if (typeof params.data === 'undefined') {
            return true;
        }
        this.data = params.data;
        this.#loadPlansFromCache();
        this.#evaluateData(params);
        this.#evaluateInventoryData(params);
        return true;
    }

    getDatePipeString() {
        return this.localeService.datePipeString(this.locale);
    }

    #evaluateData(params: ICellRendererParams | undefined) {
        if (!params) {
            return;
        }

        const plansWithThisAsset = this.plans.filter((x) =>
            x.planAssets?.map((y) => y.tenantAssetId).includes(params.data.id)
        );
        if (plansWithThisAsset.length === 0) {
            this.currentPlan = undefined;
            this.futurePlans = [];
            return;
        }

        const now = new Date();

        // Evaluate "isOnCurrentPlan"
        const currentPlans = plansWithThisAsset.filter(
            (x) => new Date(x.planStart) < now && new Date(x.planEnd) > now
        );
        this.currentPlan = currentPlans.length > 0 ? currentPlans[0] : undefined;

        const futurePlans = plansWithThisAsset.filter((x) => new Date(x.planStart) > now);
        this.futurePlans = futurePlans.sortBy((x) => x.planStart);
    }

    #evaluateInventoryData(params: ICellRendererParams | undefined) {
        if (!params || !params.data['inventoryBooked']) {
            return;
        }

        try {
            const inventoryBooked = JSON.parse(params.data['inventoryBooked']) as {
                [key: string]: boolean;
            };

            for (const key of Object.keys(inventoryBooked)) {
                this.inventoryBooked.set(key, inventoryBooked[key]);

                this.inventoryAssets.set(
                    key + '_' + this.data.id,
                    new InventoryAssetLoader(key, this.data.id, this.apollo)
                );
            }
        } catch (error) {
            // Do nothing.
        }
    }

    #loadPlansFromCache() {
        const variables: PlansQueryArgs = {
            pastIncluded: false,
            currentIncluded: true,
            futureIncluded: true,
        };
        const result = this.apollo.client.readQuery<PlansQueryRoot>({
            query: gql`
        ${FULL_FRAGMENT_PLAN}
        query Plans(
          $pastIncluded: Boolean!
          $currentIncluded: Boolean!
          $futureIncluded: Boolean!
        ) {
          plans(
            pastIncluded: $pastIncluded
            currentIncluded: $currentIncluded
            futureIncluded: $futureIncluded
          ) {
            ...FullFragmentPlan
            planAssets {
              id
              tenantAssetId
            }
          }
        }
      `,
            variables,
        });

        this.plans = result?.plans ?? [];
    }

    async #fetchAssetDefects() {
        this.getAssetDefectsInProgress = true;
        try {
            const variables: AssetDefectsQueryArgs = {
                assetId: this.data.id,
            };

            const result = await firstValueFrom(
                this.apollo.query<AssetDefectsQueryRoot>({
                    query: gql`
            ${FULL_FRAGMENT_TENANT_DEFECT}
            query AssetDefects($assetId: String!) {
              assetDefects(assetId: $assetId) {
                ...FullFragmentTenantDefect
              }
            }
          `,
                    variables,
                    fetchPolicy: 'network-only',
                })
            );

            this.#assetDefects = result.data.assetDefects.sortBy((x) => x.timestamp, 'desc');
        } catch (error) {
        } finally {
            this.getAssetDefectsInProgress = false;
        }
    }

    async #fetchUnfinishedInventories() {
        this.getUnfinishedInventoriesInProgress = true;
        try {
            const variables: UnfinishedInventoriesByAssetQueryArgs = {
                assetId: this.data.id,
            };

            const result = await firstValueFrom(
                this.apollo.query<UnfinishedInventoriesByAssetQueryRoot>({
                    query: gql`
            ${FULL_FRAGMENT_INVENTORY}
            query UnfinishedInventoriesByAsset($assetId: String!) {
              unfinishedInventoriesByAsset(assetId: $assetId) {
                ...FullFragmentInventory
              }
            }
          `,
                    variables,
                    fetchPolicy: 'network-only',
                })
            );

            this.#unfinishedInventories = result.data.unfinishedInventoriesByAsset.sortBy(
                (x) => x.createdAt,
                'desc'
            );
        } catch (error) {
        } finally {
            this.getUnfinishedInventoriesInProgress = false;
        }
    }
}
