import { CreateAssetRemindersDialogComponent } from '../create-asset-reminders-dialog/create-asset-reminders-dialog.component';
import { AssetReminderOutput } from 'projects/shared/src/lib/graphql/output/assetReminderOutput';
import { AssetRemindersQueryRoot } from 'projects/shared/src/lib/graphql/crud/assetReminder';
import { ConfirmDialogComponent, ConfirmDialogData } from 'projects/shared/src/public-api';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';

export type ViewAssetRemindersDialogData = {
    assetId: string;
};

@Component({
    selector: 'app-view-asset-reminders-dialog',
    templateUrl: './view-asset-reminders-dialog.component.html',
    styleUrls: ['./view-asset-reminders-dialog.component.scss']
})
export class ViewAssetRemindersDialogComponent implements OnInit {

    loading = false;
    assetReminders: AssetReminderOutput[] = [];
    displayedColumns: string[] = ['reminderDate', 'reminderSent', 'comment', 'additionalReceiver', 'createdBy', 'actions'];

    constructor(
        private _dialogRef: MatDialogRef<ViewAssetRemindersDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ViewAssetRemindersDialogData,
        @Inject(MAT_DATE_LOCALE) public locale: string,
        private _toastService: DesktopToastService,
        public localeService: LocaleService,
        private _matDialog: MatDialog,
        private _apollo: Apollo,
    ) { }

    ngOnInit(): void {
        this.#loadData();
    }

    closeDialog() {
        this._dialogRef.close(undefined);
    }

    async #loadData() {
        this.loading = true;
        try {
            const result = await firstValueFrom(
                this._apollo.query<AssetRemindersQueryRoot>({
                    query: gql`
                        query AssetReminders($tenantAssetId: String!) {
                            assetReminders(tenantAssetId: $tenantAssetId) {
                                id
                                tenantId
                                tenantAssetId
                                reminderDate
                                additionalReceiver
                                reminderSent
                                comment
                                createdAt
                                createdBy
                            }
                        }`,
                    variables: { tenantAssetId: this.data.assetId },
                    fetchPolicy: 'network-only',
                })
            );

            this.assetReminders = result.data.assetReminders.sortBy((x) => x.reminderDate, 'desc');
        } catch (error) {
            this._toastService.error(new CatchError(error).message, 'ERROR');
            this._dialogRef.close(undefined);
        } finally {
            this.loading = false;
        }
    }

    editReminder(reminder: AssetReminderOutput) {
        const dialog = this._matDialog.open(CreateAssetRemindersDialogComponent, {
            autoFocus: false,
            minWidth: 680,
            maxWidth: 800,
            data: {
                assetId: reminder.tenantAssetId,
                tenantId: reminder.tenantId,
                reminder: reminder,
            },
        });

        dialog.afterClosed().subscribe((result: AssetReminderOutput) => {
            if (result) {
                // Update the reminder in the list
                this.assetReminders = this.assetReminders
                    .map((assetReminder) => assetReminder.id === result.id ? result : assetReminder)
                    .sortBy((x) => x.reminderDate, 'desc');
            }
        });
    }

    deleteAssetReminderClick(reminder: AssetReminderOutput) {
        this._matDialog.open(ConfirmDialogComponent, {
            data: ({
                title: 'Are you sure?',
                text: `Do you really want to delete the selected asset reminder?`,
                onConfirm: async (comment) => {
                    await this.deleteAssetReminder(reminder);
                }
            } as ConfirmDialogData)
        });
    }

    private async deleteAssetReminder(reminder: AssetReminderOutput) {
        this.loading = true;
        try {
            let deleteResult = await lastValueFrom(this._apollo.mutate({
                mutation: gql`
                        mutation DeleteAssetReminder($id: String!) {
                            deleteAssetReminder(id: $id) {
                                id
                            }
                        }
                    `,
                variables: { id: reminder.id }
            }));

            if (deleteResult.data) {
                this.assetReminders = this.assetReminders
                    .filter((assetReminder) => assetReminder.id !== reminder.id)
                    .sortBy((x) => x.reminderDate, 'desc');
            }
        } catch (error) {
            this._toastService.error(new CatchError(error).message, 'ERROR');
        } finally {
            this.loading = false;
        }
    }

}
